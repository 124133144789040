import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';


import { addCart, changeAddProdModal } from '../../redux/actions/actionCreators'


const StyledBuyButtons = styled.div`
    .bottom-container {
        display: flex;
        position: fixed;
        bottom: 0;
        width: 70%;
        left: 15%;
        justify-content: space-around;
        align-items: baseline;
        z-index: 4;
        @media screen and (max-width: 885px) {
                flex-direction: column;
                width: 100%;
                left: 0;
            }
        .bottom {
            width: 45%;
            z-index: 4;
            visibility: ${props => !props.isTop ? 'visible' : 'hidden'};
            opacity: ${props => !props.isTop ? '1' : '0'};
            transition: 0.5s;      
            margin-bottom: 15px !important;
            @media screen and (max-width: 885px) {
                width: 90%;
                margin: auto;
                font-size: 2rem;
                padding: 7px 1%;
            }
        }
    }
    .add-to-cart {
        display: block;
        cursor: pointer;
        font-size: 2.1rem;
        background-color: white;
        padding: 12px 2%;
        border: 2px solid #00af6b;
        border-radius: 8px;
        box-shadow:rgba(77, 77, 77, 0.34) 2px 2px 1px 0px;
        color: #00af6b;
        text-align: center;
        margin-bottom: 30px;
        :hover{
            background-color: #d6d6d6;
        }
        @media screen and (max-width: 1000px) {
            order: -1;
        }
        @media screen and (max-width: 650px) {
            font-size: 1.8rem;
            padding: 5px 2%;
        }
    }
    .oos-modal {
        position: fixed;
        top: 45%;
        left: 36%;
        background-color: rgba(227,227,227,0.9);
        padding: 20px;
        font-size: 1.6rem;
        z-index: 1;
        button {
            color: red;
            position: relative;
            top: -12px;
            right: -50%;
            background-color: inherit;
            border: none;
            font-size: 2rem;
        }
        
    }
`

const BuyButtons = props => {
    const { match, changeAddProdModal, buyNow, stockStatus } = props;

    const [isShown, setIsShown] = useState(false)
    


    const addToCart = () => {
        buyNow();
        changeAddProdModal(true)
    }


    return (
        <StyledBuyButtons >
            {stockStatus === 'instock' &&
                <>
                    <Link onClick={buyNow} to={`/${match.params.language}/cart`} className='cta-product'>{match.params.language === 'it' ? 'COMPRA ORA' : 'BUY NOW'}</Link>
                    <div onClick={addToCart} to={`/${match.params.language}/cart`} className='add-to-cart'>{match.params.language === 'it' ? 'AGGIUNGI AL CARRELLO' : 'ADD TO CART'}</div>
                </>
            }
            {stockStatus === 'outofstock' &&
                <>
                    <div onClick={() => setIsShown(true)} className='cta-product'>{match.params.language === 'it' ? 'COMPRA ORA' : 'BUY NOW'}</div>
                    <div onClick={() => setIsShown(true)} className='add-to-cart'>{match.params.language === 'it' ? 'AGGIUNGI AL CARRELLO' : 'ADD TO CART'}</div>
                </>
            }

            {isShown && <div className='oos-modal'>
                <button onClick={() => setIsShown(false)}>X</button>
                <p>{match.params.language === 'it' ? 'Il prodotto non è al momento disponibile' : 'The product is currently out of stock'}</p>
            </div>}
        </StyledBuyButtons>
    )
}

function mapStateToProps(state) {
    return {
        products: state.products,
        productPage: state.general.productPage,
        cart: state.cart,
        media: state.media,
        variations: state.variations,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addCart,
            changeAddProdModal
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyButtons)) 