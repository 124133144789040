import axios from 'axios';
import { normalize } from 'normalizr';

import * as schema from './schema'
import * as types from './actionTypes';

const url = `${process.env.REACT_APP_API_URL}`



export const addProducts = products => ({
    type: types.ADD_PRODUCTS,
    payload: products,
})

export const addCategories = categories => ({
    type: types.ADD_CATEGORIES,
    payload: categories,
})

export const setVisibleCategories = visibleCategories => ({
    type: types.SET_VISIBLE_CATEGORIES,
    payload: visibleCategories
})

export const addSubCategories = subCategories => ({
    type: types.ADD_SUB_CATEGORIES,
    payload: subCategories,
})

export const setVisibleSubCategories = visibleSubCategories => ({
    type: types.SET_VISIBLE_SUB_CATEGORIES,
    payload: visibleSubCategories
})

export const addGeneral = general => ({
    type: types.ADD_GENERAL,
    payload: general
})

export const setVisibleGeneral = visibleGeneral => ({
    type: types.SET_VISIBLE_GENERAL,
    payload: visibleGeneral
})

export const addHeader = header => ({
    type: types.ADD_HEADER,
    payload: header
})

export const addHomePage = homePage => ({
    type: types.ADD_HOME_PAGE,
    payload: homePage,
})

export const addShopNow = shopNow => ({
    type: types.ADD_SHOP_NOW,
    payload: shopNow,
})

export const addGeneralFaq = generalFaq => ({
    type: types.ADD_GENERAL_FAQ,
    payload: generalFaq,
})

export const addProductPage = productPage => ({
    type: types.ADD_PRODUCT_PAGE,
    payload: productPage,
})

export const fetchFailure = error => ({
    type: types.FETCH_FAILURE,
    payload: error
})

export const fetchSucces = () => ({
    type: types.FETCH_SUCCESS,
})

export const addCart = cart => ({
    type: types.ADD_CART,
    payload: cart
})

export const addLocation = location => ({
    type: types.ADD_LOCATION,
    payload: location
})

export const addShippingMethods = (all, it, eu, us) => ({
    type: types.ADD_SHIPPING_METHODS,
    payload: {
        all,
        it,
        eu,
        us
    }
})

export const addSelectedShipping = shippingMethod => ({
    type: types.ADD_SELECTED_SHIPPING,
    payload: shippingMethod
})

export const addMedia = media => ({
    type: types.ADD_MEDIA,
    payload: media
})

export const addCoupons = coupons => ({
    type: types.ADD_COUPONS,
    payload: coupons
})

export const setAppliedCoupon = coupon => ({
    type: types.SET_APPLIED_COUPON,
    payload: coupon
})

export const addDiscount = discount => ({
    type: types.ADD_DISCOUNT,
    payload: discount
})

export const addVariations = variations => ({
    type: types.ADD_VARIATONS,
    payload: variations
})

export const addSelectedVariation = variation => ({
    type: types.ADD_SELECTED_VARIATION,
    payload: variation
})

export const addTags = tags => ({
    type: types.ADD_TAGS,
    payload: tags
})

export const addReferralLP = referralLP => ({
    type: types.ADD_REFERRAL_LP,
    payload: referralLP,
})

export const setVisibleReferralLP = visibleReferralLP => ({
    type: types.SET_VISIBLE_REFERRAL_LP,
    payload: visibleReferralLP
})

export const addLeaflet = leaflet => ({
    type: types.ADD_LEAFLET,
    payload: leaflet
})

export const setVisibleLeaflet = visibleLeaflet => ({
    type: types.SET_VISIBLE_LEAFLET,
    payload: visibleLeaflet
})

export const changeAddProdModal = bool => ({
    type: types.CHANGE_ADD_PROD_MODAL,
    payload: bool
})

export const fetchingApi = () => async dispatch => {
    dispatch({ type: types.FETCHING_API })
    axios.get(`${url}/wp-json/data/all`)
        .then(res => {
            dispatch(addMedia(normalize(res.data.media, [schema.media])))
            dispatch(addMedia(normalize(res.data.media2, [schema.media])))
            dispatch(addMedia(normalize(res.data.media3, [schema.media])))
            dispatch(addMedia(normalize(res.data.media4, [schema.media])))
            dispatch(addMedia(normalize(res.data.media5, [schema.media])))
            dispatch(addMedia(normalize(res.data.media6, [schema.media])))
            dispatch(addMedia(normalize(res.data.media7, [schema.media])))
            dispatch(addProducts(normalize(res.data.products, [schema.products])))
            dispatch(addCategories(normalize(res.data.categories, [schema.categories])))
            dispatch(addSubCategories(normalize(res.data.subcategories, [schema.subCategories])))
            dispatch(addReferralLP(normalize(res.data.referralLP, [schema.referralLP])))
            dispatch(addLeaflet(normalize(res.data.leaflet, [schema.leaflet])))
            dispatch(addGeneral(normalize(res.data.general, [schema.general])))
            dispatch(addShippingMethods(res.data.shippingAll, res.data.shippingIt, res.data.shippingEu, res.data.shippingUs))
            dispatch(addCoupons(normalize(res.data.coupons, [schema.coupons])))
            dispatch(addTags(normalize(res.data.tags, [schema.tags])))
            dispatch(addVariations(normalize(res.data.variations, [schema.variatons])))
            dispatch(fetchSucces())
        }).catch(error => {
            dispatch(fetchFailure(error))
        })
    axios.get('https://ipapi.co/json/')
        .then(res => {
            dispatch(addLocation(res.data.country))
        })
        .catch(error => {
            dispatch(addLocation('IT'))
        })
}