import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module';

import { cleanSlug } from '../helpers/cleanCategories'
import { addCart, changeAddProdModal, addSelectedVariation } from '../redux/actions/actionCreators'
import { getCorrectMetaData } from '../helpers/getCorrectMetaData'
// import findSku from '../helpers/findSku'

import BreadCamp from '../components/categoryPage/Breadcamp'
import BlurpsBot from '../components/categoryPage/BlurpsBot';
import Gallery from '../components/productPage/Gallery';
import BuyCard from '../components/productPage/BuyCard';
import RelatedProducts from '../components/productPage/RelatedProducts';
// import TrustBox from '../components/TrustBox';
import AddedProdModal from '../components/productPage/AddedProdModal';
import Loader from '../components/Loader';
import BuyNowModal from '../components/productPage/BuyNowModal';
import Faq from '../components/categoryPage/Faq';
import RecensioniVerificate from '../components/productPage/RecensioniVerificate';

const StyledProductPage = styled.section`
    .customer-reviews {
        text-align: center;
        font-size: 4rem;
        padding: 35px 0;
        border-top: 1px solid #c1c1c1;
        margin-top: 20px;
    }
    .product-page-container {
        max-width: 1280px;      
        width: 86%;
        margin: 0 auto;
        padding-top: 45px;
        @media screen and (max-width: 1000px) {
            padding-top: 0;
        }
    }
    .cta-product {
        display: block;
        font-size: 2.2rem;
        background: linear-gradient(#00bf58, #5fbd6f);
        padding: 12px 2%;
        border-color: rgba(10,10,10,0);
        border-radius: 8px;
        box-shadow:rgb(136, 136, 136) 2px 2px 1px 0px;
        color: white;
        margin-bottom: 20px;
        text-align: center;
        :hover{
            background: linear-gradient(#008a54, #469668);
        }
        @media screen and (max-width: 1000px) {
            margin-top: 30px;
        }
        @media screen and (max-width: 650px) {
            margin-top: 10px;
            padding: 7px 2%;
            font-size: 1.8rem;
        }
    }
`

const StyledTopSection = styled.section`
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        padding-top: 0;
    }
`


const StyledContent = styled.section`
    display: flex;
    font-size: 1.6rem;
    padding: 50px 0;
    line-height: 2;
    justify-content: space-between;
    @media screen and (max-width: 1400px) {
        flex-direction: column;
    }
    div, img {
        width: 45%;
        height: min-content;
        @media screen and (max-width: 1400px) {
            width: 100%;
            padding-top: 40px;
        }
    }
`
const StyledSecure = styled.div`
    img {
        margin: 0 auto;
        padding: 50px 0;
        display: block;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }
`

const ProductPage = props => {
  const { product, products, variations, categories, subCategories, productPage, match, media, addProdModal, changeAddProdModal, addSelectedVariation, addCart, cart } = props;

  const [attributeSelected, setAttributeSelected] = useState('')
  const [stockStatus, setStockStatus] = useState('')

  useEffect(() => {
    if (Object.keys(variations.selectedVariation).length) {
      setStockStatus(variations.selectedVariation.stock_status)
    } else {
      setStockStatus(product.stock_status)
    }
  }, [variations.selectedVariation, product.stock_status])

  useEffect(() => {
    if (products.slugList.length && categories.slugList.length && subCategories.slugList.length && media.idList.length && Object.keys(productPage).length) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'productPage',
          'ecommerce': {
            'detail': {
              'products': [{
                'name': product.name,         // Name or ID is required.
                'id': product.sku,
                'price': product.price,
                'brand': 'Yogic Yantra',
                'category': findCategory(categories),
                'variant': null
              }]
            }
          },
          productId: product.id
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (product) {
      if (product.variations.length && findVariationId()) {
        addSelectedVariation(variations.data[findVariationId()])
      }
    }
  })

  const buyNow = () => {
    const updatedCart = JSON.parse(JSON.stringify(cart))
    if (attributeSelected && updatedCart[`${product.slug}_${attributeSelected}`]) {
      addProdToDataLayer(attributeSelected)
      updatedCart[`${product.slug}_${attributeSelected}`].quantity += 1;
    } else if (attributeSelected) {
      addProdToDataLayer(attributeSelected)
      updatedCart[`${product.slug}_${attributeSelected}`] = {
        slug: product.slug,
        quantity: 1,
        attribute: attributeSelected,
        variation: variations.selectedVariation.id
      }
    } else if (updatedCart[product.slug]) {
      addProdToDataLayer()
      updatedCart[product.slug].quantity += 1
    } else {
      addProdToDataLayer()
      updatedCart[product.slug] = {
        slug: product.slug,
        quantity: 1,
      }
    }
    addCart(updatedCart)
    localStorage.setItem('cart', JSON.stringify(updatedCart))
  }

  const addProdToDataLayer = (variant = null) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'buyNow',
        'ecommerce': {
          'currencyCode': 'EUR',
          'add': {                                // 'add' actionFieldObject measures.
            'products': [{                        //  adding a product to a shopping cart.
              'name': product.name,
              'id': product.sku,
              'price': product.price,
              'brand': 'Yogic Yantra',
              'category': findCategory(categories),
              'variant': variant,
              'quantity': 1
            }]
          }
        }

      }
    })
  }

  const findVariationId = () => {
    return product.variations.find(id => {
      if (variations.data[id]) {
        let attributesArr = variations.data[id].attributes.map(attribute => attribute.option)
        return attributesArr.find(attribute => attribute === attributeSelected)
      }
      return null
    })
  }

  const closeAddProdModal = () => {
    if (addProdModal) {
      changeAddProdModal(false)
    }
    return;
  }

  const findCategory = categories => (categories.visibles.filter(slug => product.categories.filter(category => category.slug === cleanSlug(slug))[0])[0])

  if (findCategory(categories) && findCategory(subCategories)) {
    const category = findCategory(categories);
    const subCategory = findCategory(subCategories)
    return (
      <StyledProductPage onClick={closeAddProdModal}>
        <Helmet>
          <title>{match.params.language === 'it' ? getCorrectMetaData(product, products, 'meta_title_it') : getCorrectMetaData(product, products, 'meta_title')}</title>
          <meta name="description" content={match.params.language === 'it' ? getCorrectMetaData(product, products, 'meta_description_it') : getCorrectMetaData(product, products, 'meta_description')} />
          <meta property="og:title" content={categories.data[category].meta_title} />
          <meta property="og:description" content={categories.data[category].meta_description} />
          {categories.data[category].meta_image && <meta property="og:image" content={categories.data[category].meta_image.guid} />}
        </Helmet>
        {
          Object.keys(productPage).length > 0 &&
            (product.variations.length === 0 || Object.keys(variations.data).length > 0) ?
            <div className='product-page-container' >
              <BreadCamp product={product} category={categories.data[category]} />
              <RecensioniVerificate type='NETREVIEWS_PRODUCT_STARS' product={product} />
              <StyledTopSection>
                <Gallery product={product} />
                <BuyCard product={product} subCategory={subCategory} category={category} attributeSelected={attributeSelected} setAttributeSelected={setAttributeSelected} buyNow={buyNow} stockStatus={stockStatus} />
              </StyledTopSection>
              <BlurpsBot category={categories.data[category]} />
              <StyledContent>
                <div className='tab-text features' dangerouslySetInnerHTML={{ __html: subCategories.data[subCategory].product_page_description }} />
                {media.data[subCategories.data[subCategory].product_page_img] && <img src={subCategories.images[subCategories.data[subCategory].product_page_img].guid} alt={match.params.language === 'it' ? media.data[subCategories.data[subCategory].product_page_img].alternative_text_it : media.data[subCategories.data[subCategory].product_page_img].alt_text} />}
              </StyledContent>
              <Faq category={categories.data[category]} showGeneral={false} />
              <h4 className='customer-reviews'>{match.params.language === 'it' ? 'Recensioni Clienti' : 'Customers Reviews'}</h4>

              {/* <TrustBox attributes={{
                                locale: "it-IT", template: "5717796816f630043868e2e8", height: "700px", width: "100%", theme: "light", tags: "eng", stars: "4,5", sku: findSku(cleanSlug(category), products, variations, product), name: product.name
                            }} /> */}
              <RecensioniVerificate type='NETREVIEWS_PRODUCT_REVIEWS' product={product} />
              <iframe title='reviews' src="https://cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbproduit_all_index.html" style={{ border: "none", width: 100 + "%", height: 100 + "%" }}  ></iframe>


              <StyledSecure>
                {media.data[productPage.blurp_img.ID] && <img src={productPage.blurp_img.guid} alt={match.params.language === 'it' ? media.data[productPage.blurp_img.ID].alternative_text_it : media.data[productPage.blurp_img.ID].alt_text} />}
              </StyledSecure>
              {product && <AddedProdModal product={product} language={match.params.language} isShown={addProdModal} />}
              <BuyNowModal product={product} buyNow={buyNow} stockStatus={stockStatus} />
            </div> :
            <Loader language={match.params.language} page='product' />
        }
        <RelatedProducts product={product} />
      </StyledProductPage>
    )
  }
  return null;
}

function mapStateToProps(state) {
  return {
    categories: state.categories,
    variations: state.variations,
    subCategories: state.subCategories,
    productPage: state.general.productPage,
    cart: state.cart,
    products: state.products,
    media: state.media,
    addProdModal: state.addProdModal
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addCart,
      changeAddProdModal,
      addSelectedVariation
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductPage));