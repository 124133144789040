import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components'

import { getTotalPrice } from '../../helpers/cartHelpers'

const StyledSuggested = styled.div`
    background-color: white;
    border-bottom: 4px solid #cdd2d2;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 10px;
    width: 100%;
    h3 {
        font-size: 2.1rem;
        font-weight: bold;
        padding-bottom: 15px;
    }
    .card {
        display: flex;
        img{
            height: 100px;
            padding-right: 20px;
        }
        .outer-right {
            width: 100%;
            h4 {
                font-size: 1.6rem;
                font-weight: bold;
            }
            .inner-bottom {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                padding-top: 15px;
                .price {
                    font-size: 1.2rem;
                    font-weight: bold;
                    padding-bottom: 10px;
                }
                a {
                    
                    color: blue;
                }
                .button {
                    display: block;
                    border: 2px solid green;
                    border-radius: 7px;
                    font-size: 1.2rem;
                    color: green;
                    padding: 10px;
                    cursor: pointer;
                }
            }
        }
    }
`

const SuggestedProduct = props => {
    const { match, products, cart, shippingMethods, coupons, media} = props;
    const [suggestedProduct, setSuggestedProduct] = useState({})

    useEffect(() => {
        if (products.slugList.length && shippingMethods.shippingSelected.length) {
            const prices = products.slugList.map(slug => {
                if(!products.data[slug].sku.toLowerCase().includes('yoni')) {
                    return {
                        slug: slug,
                        price: products.data[slug].price,
                        stock_status: products.data[slug].stock_status
                    }
                } return null;
            }).filter(price => price)
            const sorted = prices.sort((a, b) => a.price - b.price)
            const filtered = sorted.filter(product => product.price > getShippingDifference() && product.stock_status === 'instock').slice(0, 2)
            const randomItem = filtered[Math.floor(Math.random() * filtered.length)];
            setSuggestedProduct(randomItem)
            
        }
        // eslint-disable-next-line
    }, [cart, shippingMethods.shippingSelected])
    
    const getShippingDifference = () => {
        return parseInt(shippingMethods.shippingSelected[1].settings.min_amount.value) - (getTotalPrice(cart, products) - coupons.discount)
    }

    if (Object.keys(suggestedProduct).length > 0) {
        return (
            <StyledSuggested>
                <h3>{match.params.language === 'it' ? 'Spesso comprati insieme' : 'Frequently bought together'}</h3>
                <div className='card'>
                    {media.data[products.data[suggestedProduct.slug].images[0]] && <img src={products.images[products.data[suggestedProduct.slug].images[0]].src} alt={match.params.language === 'it' ? media.data[products.data[suggestedProduct.slug].images[0]].alternative_text_it : media.data[products.data[suggestedProduct.slug].images[0]].alt_text} />}

                    <div className='outer-right'>
                        {console.log(products.data[suggestedProduct.slug])}
                        <h4>{products.data[suggestedProduct.slug].name}</h4>
                        <div className='inner-bottom'>
                            <div className='inner-left'>
                                <p className='price'>{products.data[suggestedProduct.slug].price} €</p>
                            </div>
                            <Link to={`/${match.params.language}/${suggestedProduct.slug}`}className='button'>{match.params.language === 'it' ? 'Scopri' : 'Discover'}</Link>
                        </div>
                    </div>
                </div>
            </StyledSuggested>
        )
    } return null;
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products,
        shippingMethods: state.shippingMethods,
        coupons: state.coupons,
        media: state.media,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuggestedProduct));