import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components'
import uuid from 'uuid/v4'

import { getTotalPrice } from '../../helpers/cartHelpers'

const StyledRelated = styled.section`
    font-size: 1.4rem;
    h4 {
        color: white;
        background-color: black;
        text-align: center;
        width: 100%;
        padding: 15px;
    }
    .related-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 40px 0;
    }
    .card {
        width: 20%;
        text-align: center;
        :hover {
            filter: saturate(100%) brightness(120%);
        }
        @media screen and (max-width: 850px) {
            width: 45%;
        }
        img {
            width: 100%;
        }
        .price {
                padding: 10px;
                display: flex;
                justify-content: center;
                span {
                    text-decoration-line: line-through;
                    padding-right: 5px;
                }
                p {
                    font-weight: 700;
                }
            }
    }
`

const RelatedProducts = props => {
    const { match, products, cart, shippingMethods, coupons, media, productPage } = props;
    const [filteredProducts, setFilteredProducts] = useState([])

    useEffect(() => {
        if (products.slugList.length && shippingMethods.shippingSelected.length) {
            const prices = products.slugList.map(slug => {
                return {
                    slug: slug,
                    price: products.data[slug].price,
                    stock_status: products.data[slug].stock_status
                }
            })
            const sorted = prices.sort((a, b) => a.price - b.price)
            const filtered = sorted.filter(product => product.price > getShippingDifference() - 20 && product.stock_status === 'instock').slice(0, 10)
            const randomfiltered = []
            for (let i = 0; i < 5; i++) {
                const randomItem = filtered[Math.floor(Math.random() * filtered.length)];
                randomfiltered.push(randomItem)
                const index = filtered.indexOf(randomItem);
                if (index > -1) {
                    filtered.splice(index, 1);
                }
            }
            setFilteredProducts(randomfiltered)
        }
        // eslint-disable-next-line
    }, [cart, shippingMethods.shippingSelected])

    const getShippingDifference = () => {
        return parseInt(shippingMethods.shippingSelected[1].settings.min_amount.value) - (getTotalPrice(cart, products) - coupons.discount)
    }

    return (
        <StyledRelated id='related'>
            <h4>{productPage.related_title}</h4>
            <div className='related-container'>
                {filteredProducts.map(product => {
                    const slug = product.slug
                    const prod = products.data[slug]
                    return (
                        <Link key={uuid()} className='card' to={`/${match.params.language}/${product.slug}`}>
                            {media.data[prod.images[0]] && <img src={products.images[prod.images[0]].src} alt={match.params.language === 'it' ? media.data[prod.images[0]].alternative_text_it : media.data[prod.images[0]].alt_text} />}
                            <h5>{prod.name}</h5>
                            <div className='price'>
                                {prod.sale_price && <span>{prod.regular_price}€</span>}
                                <p>{prod.price} €</p>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </StyledRelated>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products,
        shippingMethods: state.shippingMethods,
        coupons: state.coupons,
        media: state.media,
        productPage: state.general.productPage,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RelatedProducts));