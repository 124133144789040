import React from 'react';
import styled from 'styled-components';

const StyledAssistanceProducts = styled.section`
    padding-top: 20px;
    max-width: 80%;
    margin: 0 auto;
    h3 {
        padding: 40px 0;
        font-size: 2.6rem;
    }
    p{
        font-size: 1.6rem;
        line-height: 1.5
    }
    .description{
        padding-bottom: 30px;
    }
    .card{
        border-top: 1px solid #80808054;
        padding: 30px 0;
        .info{
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            img{
                height: 45px;
                margin-right: 20px;
            }
            p{
                font-weight: bolder;
                color: black;
            }
        }
        .button{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;
            font-size: 1.6rem;
            border: 1px solid black;
            padding: 8px 20px;
            width: 160px;
            p{
                color: black;
            }
            img{
                height: 8px;
            }
        }
    }
`

const AssistanceProductsPage = props => {
    const { match } = props;

    return (
        <StyledAssistanceProducts>
            <h3>{match.params.language === 'it' ? 'Hai domande su taglia, misura o come praticare al meglio con i nostri prodotti?' : 'Do you have problems with your order?'}</h3>
            <p className='description'>{match.params.language === 'it' ? 'Prima di contattarci se vuoi puoi dare un’occhiata al nostro blog dove puoi trovare informazioni utili, esrcizi e tutto quello che c’è da sapere sui nostri prodotti. Se hai ancora dubbi o hai una domanda specifica contattaci, saremo felici di risponderti! ' : 'We are here to solve any problem you could have with your order. Contact us and we will reply directly from our logistics center'}</p>
            <div className='card'>
                <a className='info' href={match.params.language === 'it' ? 'https://blog.yogicyantra.com/blog/contatti/' : 'https://blog.yogicyantra.com/blog/en/contact/'}>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/06/Mail-logo-blu.png" alt="" />
                    <p>{match.params.language === 'it' ? 'Scrivici ' : 'Contact Us'}</p>
                </a>
                <p>{match.params.language === 'it' ? 'Potremmo avere bisogno di uno due giorni per risponderti' : 'We may need one or two days to reply to you'}</p>
                <a className='button' href={match.params.language === 'it' ? 'https://blog.yogicyantra.com/blog/contatti/' : 'https://blog.yogicyantra.com/blog/en/contact/'}>
                    <p>{match.params.language === 'it' ? 'Scrivici' : 'Contact Us'}</p>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/06/Arrow-black-orizontal.png" alt="" />
                </a>
            </div>
            <div className='card'>
                <a className='info' href='http://m.me/yogicyantra'>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2022/06/Facebook_Messenger_logo_2018.svg_.png" alt="" />
                    <p>{match.params.language === 'it' ? 'Chatta con noi' : 'Chat with us'}</p>
                </a>
                <p>{match.params.language === 'it' ? 'Scrivici su Messenger, solitamente rispondiamo in meno di 24 ore' : 'Write us on Messenger, usually we respond in less than 24 hours'}</p>
                <a className='button' href='http://m.me/yogicyantra'>
                    <p>{match.params.language === 'it' ? 'Chatta' : 'Chat'}</p>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/06/Arrow-black-orizontal.png" alt="" />
                </a>
            </div>
        </StyledAssistanceProducts>
    )
}

export default AssistanceProductsPage;